import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { appstoreUrl } from "../constants";
import Seo from "../components/seo";

const Event = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const fromApp = params.get("fromApp") === "yes"

  return (
    <div
      className="vh-100 vw-100 center w-100 tc absolute"
      style={{ backgroundImage: 'linear-gradient(to left, #9114FF, #0e198c)' }}
    >
      <Seo
        title="StreamKit 3.0 Launch | StreamKit"
        description=""
      />
      <section>
        <div className="tc mw6 center ph5 pv4 mt4">
          <div className="f1 white tc b">
            Hashtags have arrived 🎉
          </div>
          <div className="f3 mt3 mb4 white lh-copy tc">
            Generate, create, and manage hashtags for your favorite games. Hashtags will automatically appear before you post!
          </div>
          <div className="f3 mb4 white lh-copy tc">
            Download the latest version of StreamKit to get immediate access - no subscription required!
          </div>
          {!fromApp && (
            <OutboundLink href={appstoreUrl} target="_blank" className="no-underline" rel="noreferrer">
              <div
                className="grow pointer bg-white br3 pa3 flex flex-row items-center justify-center h3"
              >
                <StaticImage
                  backgroundColor="transparent"
                  placeholder="none"
                  height={30}
                  width={30}
                  src="../images/apple-logo-black.png"
                  alt="app store"
                  quality={100}
                />
                <div className="dark-gray b f5 ph2">Download StreamKit!</div>
              </div>
            </OutboundLink>
          )}
        </div>
      </section>
    </div>
  );
}

export default Event;
